'use client'

import { useLocale } from 'next-intl';
import { useRef, useEffect, useState } from 'react';

import { useUserStore } from '@utils/user';
import { useIntersection } from '@hooks/useIntersection';
import { pushGAEvent } from './GAScript';

interface GAObserverProps {
  event: string;
  values: any;
}

export const GAObserver = ({ event, values }: GAObserverProps) => {

  const triggerRef = useRef(null);
  const isVisible = useIntersection(triggerRef, "0px");
  const [isFired, setFired] = useState(false);

  const userStore = useUserStore();
  const locale = useLocale();

  useEffect(() => {
    if (isVisible && !isFired) {
      setFired(true);
      pushGAEvent(userStore, event, locale, values);
    }
  }, [isVisible]);

  return <div ref={triggerRef}></div>;
};
