'use client';
import { useState } from 'react';

import { LazyLoader } from '@components/Lazy';
import { fetchArticlesList, FetchParams } from '@components/Lazy/fetchArticlesList';
import ArticleTile, { ArticleTileProps } from '@components/Article/ArticleTile';
import { useLocale, useTranslations } from 'next-intl';

export type TileAutoLoaderProps = {
  initialPosts: any[];
  fetchParams: FetchParams;
  lazyLoaderClassName?: string;
} & Omit<ArticleTileProps, 'article'>;

function TileAutoLoader({ initialPosts, fetchParams, lazyLoaderClassName, ...tileLayout }: TileAutoLoaderProps) {
  const locale = useLocale();
  const t = useTranslations('main.posts');
  const [posts, setPosts] = useState<any[]>(initialPosts);
  const [finished, setFinished] = useState(false);

  const onChange = async (isVisible: boolean) => {
    if (isVisible && posts.length) {
      const newPosts = await fetchArticlesList({
        ...fetchParams,
        langName: locale,
        lastDate: posts.length > 0 ? posts[posts.length - 1].dateGmt : undefined,
      });
      if (!newPosts || newPosts.length === 0) {
        setFinished(true);
        return;
      }

      setPosts(posts.concat(newPosts));
    }
  };

  if (finished && posts.length === 0) {
    return <>{t('noResults')}</>;
  }

  return (
    <>
      {posts.map((post, index) => (
        <ArticleTile key={index} {...tileLayout} article={post} />
      ))}
      {!finished && <LazyLoader className={lazyLoaderClassName} onChange={onChange}></LazyLoader>}
    </>
  );
}

export default TileAutoLoader;
