import cn from 'classnames';
import Image from 'next/image';

import ArticleBadges from './atoms/ArticleBadges';

import { getArticleBadges } from '@lib/article/getters';
import { getArticleImgUrl } from '@lib/image/getters';
import { Link } from '@lib/navigation';
import { WRPost } from '@utils/api/server';
import { WRAuthorRemapped } from '@utils/api/server/wr/post';
import { ArticleI } from 'types/article';
import { Author } from 'types/author';

import VideoIcon from 'public/static/post/video.svg';
import PodcastIcon from 'public/static/post/podcast.svg';
import { getArticleType } from '@utils/article/type';
import _merge from 'lodash/merge';

type TileLayout = {
  visibility: 'visible' | 'hidden';
  tileSize: 'small' | 'medium' | 'large';
  titlePosition: 'inside' | 'below' | 'right' | 'hidden';
  authorPosition: 'below-title' | 'below-tile' | 'hidden';
  mediaIconPosition: 'middle' | 'top-right' | 'top-left' | 'hidden';
  mediaIconSize: 'small' | 'medium' | 'large';
  badgesPosition: 'by-title' | 'above-title' | 'top-left' | 'top-right' | 'hidden';
  // categoryBadgeVariant: 'visible' | 'hidden';
  badgeTextVariant: 'visible' | 'hidden';
  badgeIconVariant: 'visible' | 'hidden';
};

export interface ArticleTileProps {
  mobileLayout?: Partial<TileLayout>;
  desktopLayout?: Partial<TileLayout>;
  article: ArticleI | WRPost;
  imgLazyLoading?: boolean;
}

export const defaultLayout: {
  desktopLayout: TileLayout;
  mobileLayout: TileLayout;
} = {
  desktopLayout: {
    visibility: 'visible',
    tileSize: 'large',
    mediaIconSize: 'large',
    titlePosition: 'inside',
    authorPosition: 'below-title',
    badgesPosition: 'above-title',
    badgeIconVariant: 'visible',
    badgeTextVariant: 'visible',
    mediaIconPosition: 'middle',
  },
  mobileLayout: {
    visibility: 'visible',
    tileSize: 'small',
    mediaIconSize: 'large',
    titlePosition: 'right',
    authorPosition: 'hidden',
    badgesPosition: 'above-title',
    badgeIconVariant: 'visible',
    badgeTextVariant: 'visible',
    mediaIconPosition: 'middle',
  },
};

export default function ArticleTile(properties: ArticleTileProps) {
  const props = _merge({}, defaultLayout, properties);
  const { imgLazyLoading, article } = props;
  const formattedDate = new Date(
    // @ts-ignore
    article.dateGmt || article.date_gmt || 0,
  ).toLocaleDateString('pl-PL');

  if (!article) {
    return null;
  }

  const articleType = getArticleType(article);

  const Author = (props: Partial<ArticleAuthorProps>) => {
    return <ArticleAuthor {...props} author={article.author} formattedDate={formattedDate} />;
  };

  const hasInnerDesktopTitleContainer = props.desktopLayout.titlePosition === 'inside';
  const hasInnerMobileTitleContainer = props.mobileLayout.titlePosition === 'inside';

  const componentVisibilityClasses = {
    hidden: props.mobileLayout.visibility === 'hidden',
    'lg:hidden': props.desktopLayout.visibility === 'hidden',
    flex: props.mobileLayout.visibility === 'visible',
    'lg:flex': props.desktopLayout.visibility === 'visible',
  };

  const titleContainerInsideClass = cn('flex-col justify-end h-1/2 relative', {
    'bg-gradient-to-t from-black/80 to-black/0': hasInnerMobileTitleContainer,
    'lg:bg-gradient-to-t lg:from-black/80 lg:to-black/0': hasInnerDesktopTitleContainer,
    hidden: props.mobileLayout.titlePosition !== 'inside',
    flex: props.mobileLayout.titlePosition === 'inside',
    'lg:flex': props.desktopLayout.titlePosition === 'inside',
    'lg:hidden': props.desktopLayout.titlePosition !== 'inside',
    'lg:gap-3 lg:p-8': props.desktopLayout.tileSize === 'large',
    'lg:gap-2 lg:p-6': props.desktopLayout.tileSize === 'medium',
    'lg:gap-1 lg:p-4': props.desktopLayout.tileSize === 'small',
    'gap-3 p-8': props.mobileLayout.tileSize === 'large',
    'gap-2 p-6': props.mobileLayout.tileSize === 'medium',
    'gap-1 p-4': props.mobileLayout.tileSize === 'small',
    'rounded-b-2xl': props.mobileLayout.authorPosition === 'below-tile',
    'lg:rounded-b-2xl': props.desktopLayout.authorPosition === 'below-tile',
    'rounded-b-none': props.mobileLayout.authorPosition !== 'below-tile',
    'lg:rounded-b-none': props.desktopLayout.authorPosition !== 'below-tile',
  });

  const titleSizeClass = {
    'text-md font-regular': props.mobileLayout.tileSize === 'small',
    'text-xl font-medium': props.mobileLayout.tileSize === 'medium',
    'text-2xl font-medium': props.mobileLayout.tileSize === 'large',
    'lg:text-md lg:font-regular': props.desktopLayout.tileSize === 'small',
    'lg:text-xl lg:font-medium': props.desktopLayout.tileSize === 'medium',
    'lg:text-2xl lg:font-medium': props.desktopLayout.tileSize === 'large',
  };

  const authorByTitleClass = {
    'lg:hidden': props.desktopLayout.authorPosition !== 'below-title',
    hidden: props.mobileLayout.authorPosition !== 'below-title',
  };

  const authorBelowTileClass = {
    'lg:hidden':
      (props.desktopLayout.authorPosition !== 'below-tile' && props.desktopLayout.titlePosition !== 'below') ||
      props.desktopLayout.authorPosition === 'hidden',
    hidden:
      (props.mobileLayout.authorPosition !== 'below-tile' && props.mobileLayout.titlePosition !== 'below') ||
      props.mobileLayout.authorPosition === 'hidden',
    'lg:block':
      props.desktopLayout.authorPosition === 'below-tile' ||
      (props.desktopLayout.titlePosition === 'below' && props.desktopLayout.authorPosition === 'below-title'),
    block:
      props.mobileLayout.authorPosition === 'below-tile' ||
      (props.mobileLayout.titlePosition === 'below' && props.mobileLayout.authorPosition === 'below-title'),
  };

  const belowContainerClasses = {
    'flex-col items-start gap-2 justify-between rounded-b-2xl': true,
    flex: props.mobileLayout.titlePosition === 'below' || props.mobileLayout.authorPosition === 'below-tile',
    hidden: props.mobileLayout.titlePosition !== 'below' && props.mobileLayout.authorPosition !== 'below-tile',
    'lg:flex': props.desktopLayout.titlePosition === 'below' || props.desktopLayout.authorPosition === 'below-tile',
    'lg:hidden': props.desktopLayout.titlePosition !== 'below' && props.desktopLayout.authorPosition !== 'below-tile',
    'lg:gap-3 lg:p-6': props.desktopLayout.tileSize === 'large',
    'lg:gap-2 lg:p-4': props.desktopLayout.tileSize === 'medium',
    'lg:gap-1 lg:p-2': props.desktopLayout.tileSize === 'small',
    'gap-1 p-6': props.mobileLayout.tileSize === 'large',
    'gap-2 p-4': props.mobileLayout.tileSize === 'medium',
    'gap-1 p-2': props.mobileLayout.tileSize === 'small',
  };

  const rightContainerClasses = {
    'w-full flex-col items-start gap-2 justify-between rounded-b-2xl dark:bg-dark-500': true,
    flex: props.mobileLayout.titlePosition === 'right',
    hidden: props.mobileLayout.titlePosition !== 'right',
    'lg:flex': props.desktopLayout.titlePosition === 'right',
    'lg:hidden': props.desktopLayout.titlePosition !== 'right',
    'lg:gap-3 lg:p-8': props.desktopLayout.tileSize === 'large',
    'lg:gap-2 lg:p-6': props.desktopLayout.tileSize === 'medium',
    'lg:gap-1 lg:p-4': props.desktopLayout.tileSize === 'small',
    'gap-3 p-8': props.mobileLayout.tileSize === 'large',
    'gap-2 p-6': props.mobileLayout.tileSize === 'medium',
    'gap-1 p-4': props.mobileLayout.tileSize === 'small',
  };

  const imgClass = {
    'absolute h-full w-full rounded-t-2xl object-cover transition-transform duration-1000 group-hover:scale-105': true,
    'rounded-b-2xl': props.mobileLayout.titlePosition !== 'hidden',
    'rounded-b-none': props.mobileLayout.titlePosition === 'hidden',
    'lg:rounded-b-2xl': props.desktopLayout.titlePosition !== 'hidden',
    'lg:rounded-b-none': props.desktopLayout.titlePosition === 'hidden',
  };

  const imgContainerClass = {
    'relative flex w-full flex-col justify-end overflow-hidden rounded-2xl': true,
    'max-w-[300px]': props.mobileLayout.titlePosition === 'right',
    'max-w-[unset]': props.mobileLayout.titlePosition !== 'right',
    'lg:max-w-[300px]': props.desktopLayout.titlePosition === 'right',
    'lg:max-w-[unset]': props.desktopLayout.titlePosition !== 'right',
    'min-h-64': props.mobileLayout.titlePosition !== 'right',
    'min-h-32': props.mobileLayout.titlePosition === 'right',
    'lg:h-full': props.desktopLayout.titlePosition === 'inside',
    'lg:h-[200px]': props.desktopLayout.tileSize === 'small',
    'lg:h-[260px]': props.desktopLayout.tileSize !== 'small' && props.desktopLayout.titlePosition !== 'inside',
  };

  const mediaIconClass = {
    'absolute top-2 left-2 transform-none': props.mobileLayout.mediaIconPosition === 'top-left',
    'absolute top-2 right-2 transform-none': props.mobileLayout.mediaIconPosition === 'top-right',
    'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2':
      props.mobileLayout.mediaIconPosition === 'middle',
    hidden: props.mobileLayout.mediaIconPosition === 'hidden',
    'lg:absolute lg:top-2 lg:left-2 lg:transform-none': props.desktopLayout.mediaIconPosition === 'top-left',
    'lg:absolute lg:top-2 lg:right-2 lg:left-[unset] lg:transform-none':
      props.desktopLayout.mediaIconPosition === 'top-right',
    'lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2':
      props.desktopLayout.mediaIconPosition === 'middle',
    'lg:hidden': props.desktopLayout.mediaIconPosition === 'hidden',
    'lg:w-16 lg:h-16': props.desktopLayout.mediaIconSize === 'large',
    'lg:w-12 lg:h-12': props.desktopLayout.mediaIconSize === 'medium',
    'lg:w-8 lg:h-8': props.desktopLayout.mediaIconSize === 'small',
    'w-16 h-16': props.mobileLayout.mediaIconSize === 'large',
    'w-12 h-12': props.mobileLayout.mediaIconSize === 'medium',
    'w-8 h-8': props.mobileLayout.mediaIconSize === 'small',
  };

  const isMobileSomethingBelow =
    props.mobileLayout.titlePosition === 'below' || props.mobileLayout.authorPosition === 'below-tile';
  const isDesktopSomethingBelow =
    props.desktopLayout.titlePosition === 'below' || props.desktopLayout.authorPosition === 'below-tile';

  return (
    <Link
      href={`/${article.slug}`}
      className={cn('group relative h-full flex-col overflow-hidden rounded-2xl', componentVisibilityClasses)}
    >
      <div
        className={cn('flex flex-row overflow-hidden', {
          'h-full': !isMobileSomethingBelow,
          'lg:h-full': !isDesktopSomethingBelow,
          'h-[unset]': isMobileSomethingBelow,
          'lg:h-[unset]': isDesktopSomethingBelow,
        })}
      >
        <div className={cn(imgContainerClass)}>
          <Image
            src={getArticleImgUrl(article, 'large')}
            loading={!imgLazyLoading && typeof imgLazyLoading !== 'undefined' ? 'eager' : 'lazy'}
            width={700}
            height={500}
            alt={article.title}
            className={cn(imgClass)}
          />
          {articleType === 'video' && <VideoIcon className={cn(mediaIconClass)} />}
          {articleType === 'podcast' && <PodcastIcon className={cn(mediaIconClass)} />}

          <ArticleBadges badges={getArticleBadges(article)} showBadges="always" absolute={true} />
          {(hasInnerDesktopTitleContainer || hasInnerMobileTitleContainer) && (
            <div className={titleContainerInsideClass}>
              <h3 className={cn('text-white', titleSizeClass)}>{article.title}</h3>
              <div className={cn(authorByTitleClass)}>
                <Author />
              </div>
            </div>
          )}
        </div>
        <div className={cn(rightContainerClasses)}>
          <div className="flex flex-col">
            <h3 className={cn(titleSizeClass)}>{article.title}</h3>
            <div className={cn(authorByTitleClass)}>
              <Author isRegularBackground />
            </div>
          </div>
        </div>
      </div>
      <div className={cn(belowContainerClasses)}>
        <h3
          className={cn(titleSizeClass, {
            hidden: props.mobileLayout.titlePosition !== 'below',
            'lg:hidden': props.desktopLayout.titlePosition !== 'below',
            block: props.mobileLayout.titlePosition === 'below',
            'lg:block': props.desktopLayout.titlePosition === 'below',
          })}
        >
          {article.title}
        </h3>
        <div className={cn(authorBelowTileClass)}>
          <Author isRegularBackground />
        </div>
      </div>
    </Link>
  );
}

interface ArticleAuthorProps {
  author: Author | WRAuthorRemapped | null;
  formattedDate: string;
  isRegularBackground?: boolean;
}
const ArticleAuthor = ({ author, formattedDate, isRegularBackground }: ArticleAuthorProps) => {
  const authorName = author ? `${author.firstName} ${author.lastName}` : 'Guest';
  const userCaption = [author?.userCaption, formattedDate].filter((q) => q).join(' | ');

  const nameClass = isRegularBackground ? 'text-black dark:text-white' : 'text-white';
  const titleClass = isRegularBackground ? 'text-premium-900' : 'text-white';

  return (
    <div className={cn('flex items-center')}>
      <Image
        src={author?.avatar || '/static/default-avatar.png'}
        alt={authorName}
        loading="lazy"
        width={48}
        height={48}
        className="mr-1 h-12 w-12 rounded-full bg-premium-100"
      />
      <p className={cn('ml-2 flex flex-col text-xs')}>
        <span className={cn(nameClass, 'font-bold')}>{authorName}</span>{' '}
        <span className={titleClass}>{userCaption}</span>
      </p>
    </div>
  );
};
